.searchPage {
    h1 {
        font-size: 2.5rem;
        position: absolute;
        margin: 0;
        padding: 30px 0;
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        left: 0;
        top: 0;
    }
    .searchPage-container {
        height: 100vh;
    }
    h1 {
        text-align:center;
    }
    .search-input-container {
        position: relative;

        .search-results-container {
            position: absolute;
            width: calc(100% - 30px);
            top: 50px;
            left: 15px;
            max-height: 40vh;
            overflow-y: auto;
            padding-bottom: 1px;

            &.no-package-found {
                border: 1px solid rgba(0, 0, 0, 0.125);
                padding: 12px 15px;
                border-radius: 4px;
            }

            .list-group {
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-bottom: 0;
                border-radius: 4px;
                overflow: hidden;
                .list-group-item {
                    border-top:1px solid rgba(0, 0, 0, 0.125);
                    border-left:0;
                    border-right:0;
                    padding:0;
                    &:first-child {
                        border-top:0;
                    }
                    &:last-child {
                        border-bottom:1px solid rgba(0, 0, 0, 0.125);
                    }

                    a {
                        padding:12px 20px;
                        display: block;
                        text-decoration: none;
                        &:hover {
                            background-color: rgba(0, 0, 0, 0.03)
                        }
                    }
                }
            }
        }
    }
}

.package-detail-container{
    h1 {
        font-size: 1.5rem;
        padding: 1rem 0;
        font-weight: normal;

        span {
            font-weight: bold;
            color: #007bff;
            text-transform: capitalize;
        }
    }
}

.loader {
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(255,255,255,0.6);

    div.spinner-holder {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}